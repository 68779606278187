@import 'style/variables';

.button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $white;
    font-size: 1.125rem;

    &:hover {
        cursor: pointer;
    }
}

.icon {
    fill: $white;
}
