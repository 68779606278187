@import 'style/variables';

.breadcrumb {
    margin-bottom: $breadcrumb-margin;
}

.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $grid-gap;
}

.card {
    grid-column: 1 / span 2;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.searchInput {
    flex-grow: 1;
}

.searchRow {
    display: flex;
    gap: 1rem;
}

.addLocationButton {
    min-width: max-content;
}
