@import 'style/variables';

.button {
    padding: 6px 16px;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.75;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    border-radius: 4px;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        &:not(.disabled) {
            cursor: pointer;
        }
    }
}

.primary {
    border: none;
    background-color: $blue;
    color: $white;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px,
        rgb(0 0 0 / 12%) 0px 1px 5px 0px;

    &:hover {
        background-color: $blue-shaded;
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px,
            rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    }

    &.disabled {
        background-color: rgba(0, 0, 0, 0.12);
        box-shadow: none;
    }
}

.secondary {
    border: 1px solid $blue50;
    background-color: transparent;
    color: $blue;

    &:hover {
        background-color: $blue04;
        border-color: $blue;
    }

    &.disabled {
        border: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.dense {
    padding: 6px 11px;
    font-size: 0.625rem;
}

.disabled {
    color: rgba(0, 0, 0, 0.26);
    pointer-events: none;
}
