@import 'style/variables';

.container {
    position: relative;
    min-height: 324px; // 300px chart height + 24px legend height
}

.buttonRow {
    display: flex;
    gap: 16px;
    position: absolute;
    right: 0;
    margin-right: calc($card-padding + 50px); // 50px "padding" in chart
}
