@import 'style/variables';

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid $blue;
}

@mixin tabsBaseStyles {
    flex: 1 0px;
    text-align: center;
    margin-bottom: -1px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    padding: 1rem 2rem;
    font-size: 1rem;

    cursor: pointer;
    user-select: none;

    input {
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &:hover {
        color: $black90;
    }
    
    @content;
}

.tabLabelSelected {
    @include tabsBaseStyles {
        border-color: $blue;
        color: $black90;
        font-weight: 700;
    }
}


.tabLabelUnselected {
    @include tabsBaseStyles {
        border-color: transparent;
        color: $black60;
        font-weight: 400;
    }
}