@import 'style/variables';

.background {
    position: fixed;
    display: flex;
    background-color: rgba(black, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.modal {
    margin: auto;
    padding: $card-padding;
    background-color: $white;
    border-radius: 4px;
}

.modalCloseButton {
    display: block;
    margin-left: auto;
    margin-top: 16px 16px 0 auto;
    border: none;
    color: $black60;
}