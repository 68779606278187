@import 'style/variables';

.frame {
    flex-direction: column;
    padding: 1rem;
    border: 1px solid;
    border-radius: 4px;
}

.frameDepthEven {
    background-color: $blue04;
    border-color: $blue20;
}

.frameDepthOdd {
    background-color: $white;
    border-color: $black20;
}
