@import 'style/variables';

.header {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: $blue;
    padding: 16px;

    .iconLink {
        height: 22px;
    }

    .dashboardIcon {
        fill: $white;
    }

    h1 {
        flex-grow: 1;
        color: $white;
        font-weight: normal;
        font-size: 1.5rem;
        margin: 0;

        a {
            text-decoration: none;
            color: inherit;
        }
    }
}
