@import 'style/variables';

.buttonRow {
    display: flex;
    padding: 8px 0;
    gap: 16px;
}

.actionResultSuccess {
    margin: auto;
    color: $text-success-color;
}

.actionResultError {
    margin: auto;
    color: $text-error-color;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}
