@import 'style/variables';

.breadcrumb {
    margin-bottom: $breadcrumb-margin;
}

.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $grid-gap;
}

.stateTimeline {
    grid-column: 1 / span 2;
}

.loadChart {
    grid-column: 1 / span 2;
}

.containerNotFoundChargePoint {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}