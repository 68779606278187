@import 'style/variables';

.phaseLoadHint {
    font-size: 0.75rem;
    color: gray;
    margin: 8px 0 8px 50px;
}

.backToClustersButton {
    @extend .phaseLoadHint;
    border: none;
    background: none;
    padding: 0;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}
