@import 'style/variables';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > button {
        max-width: max-content;
    }
}

.textFieldRow {
    display: flex;
    gap: 1rem;
}

.spinner {
    margin-top: auto;
    margin-bottom: auto;
}

.success {
    margin-top: auto;
    margin-bottom: auto;
    color: $text-success-color;
}

.error {
    margin-top: auto;
    margin-bottom: auto;
    color: $text-error-color;
}

.trashButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: transparent;
    &:not([disabled]) {
        cursor: pointer;
    }
}

.trashIcon {
    color: $black60;
    width: 24px;
    height: 24px;
}

.trashIconDisabled {
    color: $black20;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}
