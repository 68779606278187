@import 'style/variables';

.icon {
    width: 24px;
    height: 24px;
    color: $black60;
}

.disabled {
    color: $black20;
}

.button {
    border: none;
    background-color: transparent;
    &:not([disabled]) {
        cursor: pointer;
    }
}
