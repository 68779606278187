.headerWrapper {
    display: flex;
    margin-bottom: 2rem;
}

.inputContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    label {
        width: max-content;
        flex-shrink: 0;
        font-weight: bolder;
    }
    input {
        flex: 1;
        flex-basis: 300px;
    }
}

.checkboxContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;

    .checkbox {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
    }
}

.modal {
    max-width: 400px;
}

.card {
    margin-top: 1.5rem;
}
