.listContainer,
.itemContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inputRow {
    display: flex;
    gap: 1rem;
}

.trashButton {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 0.5rem;
}

.sensorAddButton {
    max-width: max-content;
}
