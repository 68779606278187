.container {
    border-bottom: 1rem;
    
    &:not(:last-child) {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
    }
}

.tooltipTitle {
    color: black;
    font-weight: 600;
    margin-bottom: 8px;
}

.stateText {
    font-weight: 600;
    margin-bottom: 4px;
}

.chartHeader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    justify-items: center;
    
    margin-left: 50px;
    margin-right: 50px;
}

.buttonRow {
    display: flex;
    gap: 8px;
    margin-left: auto;
}

.chartTitle {
    grid-column-start: 2;
    font-weight: 600;
    margin: 0;
}

.tooltip {
    position: fixed;
    box-shadow: rgb(0 0 0 / 14%) 0px 4px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    background-color: white;
    padding: 4px;
    border-radius: 4px;
}
