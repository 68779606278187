.table-container {
    @apply overflow-x-scroll max-w-4xl;

    .ka-row {
        cursor: pointer;
        &:hover {
            @apply bg-gray-100;
        }
    }
}

.row-problem {
    background-color: #ffe76d !important;
}
