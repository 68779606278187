@import 'variables';

@tailwind base;

@tailwind components;
@tailwind utilities;

$ka-thead-background-color: white;
$ka-cell-line-height: 1.5rem;
@import 'ka-table/style.scss';

@import 'react-tooltip/dist/react-tooltip.css';

@layer base {
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
        margin: 1rem 0;
        font-weight: 700;
    }
    a {
        @apply text-blue-600 underline;
    }

    input {
        border: 1px inset $gray;
        border-radius: 2px;
    }

    select {
        border-radius: 1rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-right: 1rem;
        padding-left: 0.5rem;
        border: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%239F9F9F%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat, repeat;
        background-position: right 0.2em top 50%;
        background-size: 0.5em auto;
    }

    * select:after {
        content: ' ';
        position: absolute;
        top: 50%;
        margin-top: -2px;
        right: 8px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
    }
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    overflow-x: hidden;
}

body,
button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: $text-primary-color;
}

table {
    border-collapse: collapse;
    width: 100%;

    thead > tr,
    tbody > tr:not(:last-child) {
        border-bottom: 1px solid lightgray;
    }

    tbody > tr:hover {
        background-color: $blue04;
    }

    th {
        font-weight: 600;
    }

    td,
    th {
        text-align: left;
        line-height: 2rem;
        padding-inline: 16px;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

input {
    font-size: 1rem;
    padding: 4px 8px;
    width: 100%;
}

dl {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;

    dt {
        font-weight: 600;
    }

    dd {
        margin-left: 0;
    }
}
