@import 'style/variables';

.button {
    display: flex;
    line-height: 100%;

    > span {
        margin: auto 0 auto 8px;
    }
}

.icon {
    width: 32px;
    height: auto;
}

.icon-dense {
    width: 24px;
    height: auto;
}
