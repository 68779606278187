.modalCloseButton {
    display: block;
    margin-left: auto;
    margin-top: auto;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    word-wrap: break-word;
    min-width: 400px;
    max-width: 500px;
    min-height: 156px;
}
