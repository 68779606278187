.chargePointRow {
    position: relative;
    display: flex;
    gap: 1rem;
}

.buttonRow {
    align-items: flex-end;
    display: flex;
    margin-left: auto;
    gap: 1rem;
    margin-top: auto;
}

.trashCan {
    margin-top: auto;
    margin-bottom: auto;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}
