@import 'style/variables';

.inputRow {
    display: flex;
    gap: 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.button {
    width: max-content;
}

.commitButtonContainer {
    display: flex;
    gap: 1rem;
    > p {
        margin: auto 0;
    }
}

.commitDirty {
    color: $text-primary-color;
    font-weight: 600;
}

.commitNotDirty {
    color: $text-secondary-color;
}
