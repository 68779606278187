@import 'style/variables';

.pageWrapper {
    background-color: $light-gray;
    display: grid;
    min-height: 100vh;
    grid-template: auto 1fr auto / 1fr;
}

.content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @apply p-4 sm:p-8;
}
