.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    row-gap: 8px;
}

.legendItem {
    display: flex;
    gap: 8px;
}
