/* Colors */
$blue: rgb(25, 118, 210); // Primary
$blue04: rgba(25, 118, 210, 0.04); // Background
$blue20: rgba(25, 118, 210, 0.2); // Border
$blue50: rgba(25, 118, 210, 0.5); // Border
$blue-shaded: rgb(21, 101, 192); // Hover
$black90: rgba(0, 0, 0, 0.9);
$black60: rgba(0, 0, 0, 0.6);
$black20: rgba(0, 0, 0, 0.2);
$black10: rgba(0, 0, 0, 0.1);
$dark-green: rgb(30, 70, 32); // Text
$dark-red: rgb(95, 33, 32); // Text
$red: rgb(210, 59, 48); // Error
$light-gray: #f5f5f5; // Background
$gray: #c5c5c5; // Background
$white: #fff;
$black: #000;

/* border shadow */
$shadowCircular: '0px 0px 2px 2px #0000000d';
$shadowBlueSharp: '0 0 0 4px #b1d4f8';
$shadowRedSharp: '0 0 0 1px #e51c5c';
$menuBoxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)';

/* spacing */
$spaceXxxs: '0.125rem'; // 2px
$spaceXxs: '0.25rem'; // 4px
$spaceXs: '0.5rem'; // 8px
$spaceS: '1rem'; // 16px
$spaceM: '1.5rem'; // 24px
$spaceL: '2rem'; // 32px
$spaceXl: '3rem'; // 48px
$spaceXxl: '5rem'; // 80px

/* Text */
$text-primary-color: $black90;
$text-secondary-color: $black60;
$text-success-color: $dark-green;
$text-error-color: $dark-red;
$text-info-color: $black90;

/* Config fields */
$field-modified-background: rgb(236, 250, 243);
$field-modified-border: rgba(165, 214, 170);
$field-invalid-background: rgba(246, 240, 243);
$field-invalid-border: rgb(211, 168, 170);

/* Layout */
$grid-gap: 24px;
$breadcrumb-margin: 24px;
$card-padding: 16px;

/* Breakpoints */
$tablet-width: 768px;

@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}
