@import 'style/variables';

.noIssues {
    text-align: center;

    .noIssuesIcon {
        fill: $text-primary-color;
        width: 48px;
        height: auto;
        display: inline;
    }

    p {
        margin-block: 8px 32px;
    }
}
