.inputRow {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    margin-top: auto;
    justify-content: flex-end;
}

.modal {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    min-height: 256px;
}
