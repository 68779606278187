@import 'style/variables';

.buttonRow {
    margin-top: 16px;
    display: flex;
    gap: 16px;
}

.actionResultSuccess {
    margin: auto;
    color: $text-success-color;
}

.actionResultError {
    margin: auto;
    color: $text-error-color;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}

.deviceAdoptingModal {
    min-width: 600px;
    min-height: 212px;
}

.informationSection{
    display: flex;
    gap: 8px;
    margin-bottom: 1rem;

    > label, .label{
       min-width: 200px;
    }

    > select {
        padding-top: 0;
        padding-left: 0;
        border-radius: 0;
    }
}

.assignToLocationForm {
    display: flex;
    flex-direction: column;
    margin: 4px 0;
}