@import 'style/variables';

.closeButton {
    display: block;
    margin-left: auto;
    margin-top: 2rem;
}

.modal {
    max-width: max(60vw, min(400px, 90vw));
    @apply p-6;

    > h2 {
        @apply mt-0 mb-4 flex flex-row items-center gap-2;
    }
}

.icon {
    @apply w-6 h-6;
}

.error {
    fill: $red;
}

.info {
    fill: $blue;
}
