.breadcrumb {
    list-style: none;
    margin-block: 0;
    padding-left: 0;

    li {
        display: inline;

        &:not(:last-child) {
            margin-right: .5rem;

            &::after {
                content: '/';
                margin-left: .5rem;
            }
        }
    }
}
