@import 'style/variables';

.container {
    width: max-content;
}

.label {
    font-weight: 600;
    color: $text-secondary-color;
}

.field {
    display: block;
    font: inherit;
    border: 2px solid $black10;
    padding: 4px 8px;
    padding-right: 14px;
    margin-top: 0.25rem;
    background-color: $light-gray;
    border-radius: 4px;
}

.field:disabled {
    background-color: $black10;
    color: $black20;
}

.modified {
    background-color: $field-modified-background;
    border: 2px solid $field-modified-border;
}

.invalid {
    background-color: $field-invalid-background;
    border: 2px solid $field-invalid-border;
}

.unmodified {
    background-color: $light-gray;
    border: 2px solid $field-invalid-border;
}

.option {
    border: none;
}
