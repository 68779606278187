@import 'style/variables';

.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $grid-gap;

    .chargePoints,
    .connectorLoad,
    .clusterLoad,
    .modules {
        grid-column: 1 / span 2;
    }

    @include mobile {
        grid-template-columns: auto;

        .chargePoints,
        .connectorLoad,
        .clusterLoad {
            grid-column: auto;
        }
    }
}
