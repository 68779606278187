@import 'style/variables';

.headerWrapper {
    display: flex;
    margin-bottom: 16px;

    > h2 {
        flex-grow: 1;
        margin-bottom: 0;
    }
}

.spinner {
    margin: 32px auto;
}

.searchInput {
    margin-bottom: 16px;
}

.unCommissioned {
    background-color: $light-gray;

    &,
    & a {
        color: $text-secondary-color;
    }
}

.idWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .offlineIcon {
        height: 1rem;
        width: auto;
        fill: $text-secondary-color;
    }
}

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 2rem;
}

.checkbox {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
}
