@import 'style/variables';

.modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0px;
    min-width: 400px;
    min-height: 200px;
}

.closeButton {
    display: block;
    margin-left: auto;
    margin-top: auto;
}

.successText {
    margin: 0px;
    margin-bottom: 1rem;
    color: $text-success-color;
}

.buttonRow {
    margin-top: 16px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
