@import 'style/variables';

.buttonRow {
    margin-top: 16px;
    display: flex;
    gap: 16px;
}

.actionResultSuccess {
    margin: auto;
    color: $text-success-color;
}

.actionResultError {
    margin: auto;
    color: $text-error-color;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}

.deviceAdoptingModal {
    min-width: 600px;
    min-height: 212px;
}

.informationSection{
    display: flex;
    gap: 8px;
    margin-bottom: 1rem;

    > label, .label{
       min-width: 200px;
    }

    > select {
        padding-top: 0;
        padding-left: 0;
        border-radius: 0;
    }
}

.assignToLocationForm {
    display: flex;
    flex-direction: column;
    margin: 4px 0;
}

.devicesListData {
        padding: 4px 8px;
        margin-bottom: $spaceXxs;
        width: 100%;
        height: inherit;
        color: $black;
        background-color: $white;
        border: 1px inset #c5c5c5;
        box-shadow: $shadowRedSharp;
        font-weight: 500;
        font-family: 'SharpSans Medium', sans-serif;
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
        outline: none;
        min-height: 200px;

        &:focus {
            box-shadow: $blue;
        }

        &:disabled {
            color: $gray;
            border-color: $gray;
            background-color: $gray;
        }
}

.errorMessage {
    color: $red
}