.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 2rem;
}

.item {
    display: flex;
    gap: 1rem;
    > input {
        width: min-content;
    }
}

.field {
    max-width: 8rem;
}

.buttonContainer {
    display: flex;
    gap: 1rem;
    margin: auto 0 auto auto;
}

.trashCanButton {
    text-align: center;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}

.addConnectorButton {
    max-width: max-content;
}
