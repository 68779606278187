@import 'style/variables';

.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $grid-gap;
}

.breadcrumb {
    margin-bottom: $breadcrumb-margin;
}

.card {
    grid-column: 1 / span 2;
}
