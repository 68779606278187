@import 'style/variables';

.breadcrumb {
    margin-bottom: $breadcrumb-margin;
}

.headerWrapper {
    display: flex;

    > h2 {
        flex-grow: 1;
    }
}

.inputRow {
    display: flex;
    gap: 1rem;
}

.searchInput {
    flex-basis: 70%;
}

.limitInput {
    flex-basis: 30%;
}

.searchBar {
    margin-bottom: 2rem;

    h3 {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    > fieldset {
        all: unset;
        display: flex;
        margin-right: 1rem;
        margin-bottom: 1rem;

        > legend {
            font-weight: 600;
            margin-bottom: 0.25rem;
            padding: 0;
        }

        > input {
            width: 1rem;
            height: 1rem;
            margin-left: 0px;
        }
        > label {
            margin-right: 1rem;
        }

        :last-child {
            margin-right: 0;
        }
    }
}

.table {
    grid-column: 1 / span 2;
}

.message {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 500px;
    line-height: 1rem;
    margin: auto 0;
    padding: 0.25rem 0;
}

.messageExpanded {
    white-space: pre-wrap;
    text-overflow: unset;
    overflow-y: hidden;
}

.messageCell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.messageExpandButton {
    background: none;
    border: none;
    height: 44px;
    :hover {
        cursor: pointer;
    }
}

.chevron {
    transition: transform 150ms;
    width: 24px;
    height: 24px;
}

.chevronExpanded {
    transform: rotate(180deg);
}

.radioButton {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    gap: 2px;
}
