.card {
    > p {
        margin-top: 16px;
        margin-bottom: 0;
    }
}

.form {
    fieldset {
        margin-bottom: 16px;
    }
}

.option {
    :not(:last-child) {
        margin-bottom: 8px;
    }
}

.radio {
    display: inline-block;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
}

.currentText {
    color: gray;
}

.needsConfiguration {
    color: gray;
}
