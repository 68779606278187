@import 'style/variables';

.navigationBar {
    display: flex;
    gap: 1.5rem;
    justify-content: flex-end;
    margin-bottom: $breadcrumb-margin;

    > :first-child {
        margin-right: auto;
    }
}
