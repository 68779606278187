@import 'style/variables';

.legend {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;

    button {
        border: none;
        background: none;
        font-size: inherit;
        padding: 0;

        &:hover {
            cursor: pointer;
            background-color: rgba($blue, 0.1);
        }
    }
}

.item {
    display: flex;
}
