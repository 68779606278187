.grid {
    display: grid;
    grid-template-columns: auto auto;

    .timestamp {
        grid-column: 1 / span 2;
        margin-bottom: 8px;
    }
}

.amperage {
    text-align: right;
}
