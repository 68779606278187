@import 'style/variables';

.page {
    display: grid;
    gap: $grid-gap;
}

.goToIssuesPageButton{
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
}