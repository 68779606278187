.controllerList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.deviceId {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.name {
    margin-top: 0;
    margin-bottom: 0;
}

.row {
    display: flex;
    gap: 1rem;
}

.saveButtonContainer {
    margin-top: auto;
}

.deleteButtonContainer {
    margin-top: auto;
    margin-left: auto;
}

.modalCloseButton {
    margin-left: auto;
    margin-top: 16px;
    display: block;
}
