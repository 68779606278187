@import 'style/variables';

.page {
    background-color: $light-gray;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template: 1fr / 1fr;
    justify-items: center;
    align-items: center;

    .spinner {
        margin-inline: auto;
    }

    p {
        margin-block: 32px 0;
    }
}
