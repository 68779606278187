@import 'style/variables';

.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 2rem;
}

.checkbox {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.buttonRow {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.successText {
    margin: auto;
    color: $text-success-color;
}

.errorText {
    margin: auto;
    color: $text-error-color;
}

.modal {
    min-width: 400px;
    min-height: 156px;
}
