@import 'style/variables';

.breadcrumb {
    margin-bottom: $breadcrumb-margin;
}

.searchBar {
    display: flex;
    gap: 1rem;
    margin-bottom: 16px;
}

.regexInput {
    flex-shrink: 2;
    min-width: 96px;
}

.limitInput {
    flex-shrink: 3;
    min-width: 64px;
}

.downloadButton {
    flex-shrink: 0;
}

.log {
    text-overflow: wrap;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.fullscreenContainer {
    position: absolute;
    background-color: $light-gray;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: 1;
}

.fullscreenContent {
    margin: 32px;
}

.expandCollapseButton {
    margin-left: auto;
}
